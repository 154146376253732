<template>
	<div class="left" :class="pageShow==1?'left-edit-box-2s2':''">
		<div class="phone_box_warp">
			<img class="phone_box_img" src="/img/top_black2@2x.png">
			<div class="phone_box_container">
				<div class="content">
					<div class="avatar_box" v-if="videoData.headimgurl.url">
						<img class="avatar" :src="$baseFunc.getQiniusign(videoData.headimgurl.url)">
					</div>
					<div class="single-nickname" v-if="videoData.name">
					   <div class="nickname-arrow"><div class="nickname-line"></div><div class="nickname-crl"><span></span></div></div>
					   <span class="nickname-hys word-pre-break">{{videoData.name}}</span>
					   <div class="nickname-arrow"><div class="nickname-crl"><span></span></div><div class="nickname-line"></div></div>
					</div>
					<div class="introduce word-pre-break">{{this.videoData.author_introduce}}</div>
					<div class="video">
						<video v-if="videoData.content.lists[0]" ref="video" :controls='playVideoBool' controlslist="nodownload"
							:src="$baseFunc.getQiniusign(videoData.content.lists[0].url)"
							:poster='videoData.content.lists[0].cover.url?$baseFunc.getQiniusign(videoData.content.lists[0].cover.url):""'></video>
						<div v-if="!playVideoBool" class="video_fm"></div>
						<div  v-if="!playVideoBool" class="video_bf_icon" @click="playVideo"><span class="mbm-iconfont mbm-bofang2"></span></div>
					</div>
					<div class="title" v-if="videoData.content.lists[0]">{{videoData.content.lists[0].title}}</div>
					<div class="tag">
						<a-tag color="#E6F2FC" style="border-radius: 4px;" v-if="videoData.content.lists[0]"><span style="color:#0F58FB;">{{parseInt(videoData.content.lists[0].set_learn_nums)+parseInt(videoData.content.lists[0].real_learn_nums)}}人已学</span>
						</a-tag>
						<a-tag color="#FFE5DA" style="border-radius: 4px;" v-if="videoData.content.lists[0]"><span
								style="color:#FF4839;">试看{{videoData.content.lists[0].free_time}}分钟</span></a-tag>
					</div>
					<div class="shopping">
						<div class="shopping_tag" v-if="videoData.label">
							<span>{{videoData.label}}</span>
						</div>
						<div class="price">
							<span>￥</span>
							<span>{{videoData.price}}</span>
						</div>
						<div class="original_price">
							<s>￥{{videoData.original_price}}</s>
						</div>
						<div class="dianzan" v-if="videoData.content.lists[0]">
							<img src="/img/knowledgePayment/icon-dianzan.png">
							<span>{{$baseFunc.playbackConversion(videoData.content.lists[0].set_like_nums,videoData.content.lists[0].real_like_nums)}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="submit" v-if="pageShow==1">
			<a-button class="submit_btn" size="large" type="primary" :loading="submitLoading" @click="submit">完成编辑</a-button>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			videoData: Object,
			pageShow: Number
		},
		data() {
			return {
				playVideoBool: false, //是否播放视频
				submitLoading:false,
			};
		},
		mounted() {},
		created() {},
		beforeDestroy() {},
		watch: {},
		methods: {
			submit() { //提交
				this.$emit('submitData', {quantity_type:1})
			},
			playVideo() { //播放视频
				this.playVideoBool = true
				this.$nextTick(() => this.$refs.video.play())
			}
		},
	};
</script>

<style scoped lang="less">
	.word-pre-break {
		word-break: break-all;
		word-wrap: break-word;
	}
	// /deep/.btn {
	// 	width: 80px;
	// 	line-height: 34px;
	// 	border-radius: 4px;
	// 	border: 1px solid #D9D9D9;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// }

	// 私有部分
	.left {
		width: 517px;
		min-height: 792px;
		background: #FFFFFF;
		box-shadow: 0px 2px 28px -7px rgba(0, 0, 0, 0.06);
		border-radius: 10px;
		.phone_box_warp {
			height: calc(100% - 64px);
			width: 360px;
			margin: 32px auto;
			border-radius: 40px;
			overflow: hidden;
			position: relative;
			background: white;
			box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.04);
			padding-bottom: 16px;
			.phone_box_img {
				position: absolute;
				top: 0;
				left: 1%;
				width: 98%;
				z-index: 5;
			}
			.phone_box_container {
				height: 100%;
				width: 340px;
				border-radius: 33px;
				margin: 7px 10px 0 10px;
				border: 3px solid #F5F5F5;
				position: relative;
				overflow: hidden;
				// background-color: rgb(48, 44, 44);
			}
		}
		.content {
			width: 100%;
			height: auto;
			padding: 102px 14px;
			.avatar_box {
				width: 100%;
				display: flex;
				justify-content: center;
				margin-bottom: 16px;
				.avatar {
					width: 62px;
					height: 62px;
					border-radius: 50%;
					object-fit: cover;
				}
			}
			.single-nickname{
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					overflow: hidden;
					margin-bottom: 3px;
					.nickname-hys{
						margin: 0 10px;
						width: fit-content;
						color: #333333;
						font-size: 15px;
						font-weight: 550;
					}
					.nickname-arrow{
						flex: 1;
						min-width:16px;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 16px;
						.nickname-line{
							flex: 1;
							height: 1px;
							background-color: #CFDDF5;
						}
						.nickname-crl{
							border: 1px solid #DADADA;
							border-radius: 50%;
							width: 8px;
							height: 8px;
							display: flex;
							align-items: center;
							justify-content: center;
							span{
								width: 4px;
								height: 4px;
								background-color: #C5D2E9;
								border-radius: 50%;
							}
						}
					}
				}
			.introduce {
				display: flex;
				justify-content: center;
				color: #999999;
				font-size:14px;
				text-align: left;
				white-space: pre-line;
			}
			.video {
				width: 100%;
				height: 172px;
				margin-top: 22px;
				margin-bottom: 8px;
				border-radius: 6px;
				position: relative;
				background-color: #e0ded9;
				video,.cover-img {
					width: 100%;
					height: 100%;
					border-radius: 6px;
				}
				.cover-img {
					object-fit: cover;
				}
				.video_fm {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					background: #000000;
					opacity: 0.3;
					border-radius: 6px;
					object-fit: contain
				}
				.video_bf_icon{
					cursor: pointer;
					position: absolute;
					width: 44px;
					height: 44px;
					top: 50%;
					left: 50%;
					margin-top: -22px;
					margin-left: -22px;
					background-color: #ffffff;
					border-radius: 50%;
					span{
						font-size: 20px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-40%, -50%);
						color: #000000;
					}
				}
				.video_bf {
					cursor: pointer;
					position: absolute;
					width: 44px;
					height: 44px;
					top: 50%;
					left: 50%;
					margin-top: -22px;
					margin-left: -22px;
				}
			}
			.title {
				font-size: 15px;
				font-weight: 550;
				color: #373737;
			}
			.tag {
				margin-top: 10px;
				margin-bottom: 11px;
			}
			.shopping {
				display: flex;
				align-items: flex-end;
				position: relative;
				.shopping_tag {
					padding: 0 6px;
					height: 24px;
					background: linear-gradient(180deg, #FF853E 0%, #FF3838 100%);
					border-radius: 4px;
					display: flex;
					justify-content: center;
					align-items: center;
					span {
						font-size: 13px;
						color: #FFFFFF;
						line-height: 1;
					}
				}
				.price {
					margin-left: 8px;
					margin-bottom: -2px;
					span:nth-child(1) {
						font-size: 12px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 550;
						color: #FF4E3A;
					}
					span:nth-child(2) {
						font-size: 16px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 550;
						color: #FF2222;
					}
				}
				.original_price {
					margin-bottom: -2px;
					margin-left: 11px;

					s {
						font-size: 14px;
						color: #B6B6B6;
					}
				}

				.dianzan {
					display: flex;
					align-items: flex-end;
					position: absolute;
					right: 0;

					img {
						width: 22px;
						height: 18px;
						margin-right: 6px;
						// margin-left: 118px;
					}

					span {
						margin-bottom: -3px;
						font-size: 14px;
						color: #999999;
					}
				}
			}
		}
		.submit {
			padding: 34px 0 66px;
			display: flex;
			align-items: center;
			justify-content: center;
			.submit_btn {
				min-width: 156px;
			}
		}
	}
	.left-edit-box-2s2{
		.phone_box_warp {
			height: 728px;
		}
	}
</style>
