<template>
	<div class="box">
		<div style="height:45px;"></div>
		<div class="content_box">
			<knowledgeLeft v-if="videoData.quantity_type == 1" ref="knowledgeLeftRef" :videoData='videoData' :pageShow='pageShow' @submitData="submit" />
			<knowledgeVideoList v-if="videoData.quantity_type == 2" ref="knowledgeVideoListRef" :videoData='videoData' :pageShow='pageShow' @submitData="submit" />
			<div class="right" v-if="pageShow==1">
				<div class="top">
					<span v-for="(item, index) in topTitle.list" :class="topTitle.editIndex==index?'span_style':''" :key="index" @click="topTitle.tab(index,0)">{{ item.title }}</span>
				</div>
				<div class="content">
					<div v-if="videoData.quantity_type!=2">
						<p class="title">视频: <span style="color:#285AF9;margin-left:7px;font-weight: 400;" v-if="videoData.content.lists.length>0&&videoData.content.lists[0].progress!=101">上传中…</span></p>
						<div class="video">
							<div class="video_box" v-if="videoData.content.lists.length<1">
								<a-button class="btn" style="margin-right:10px;" @click="selectFileData.addSingleVideo(-1,'video',false)">添加视频</a-button>
							</div>
							<div class="video_box" v-else-if="videoData.content.lists[0].progress==101">
								<div class="video_img">
									<img :src="videoData.content.lists[0].cover.url?$baseFunc.getQiniusign(videoData.content.lists[0].cover.url):$baseFunc.getQiniusign(videoData.content.lists[0].url,0)"/>
								</div>
								<a-button class="btn" style="margin-left:29px;margin-right:10px;" @click="selectFileData.addSingleVideo(0,'video',false)">替换视频</a-button>
								<a-button class="btn" @click="selectFileData.addSingleImage(0,'video_cover')">修改封面</a-button>
							</div>
							<div class="loadingCon" v-else>
								<a-progress strokeWidth="6" trailColor="white" :percent="videoData.content.lists[0].progress" status="active" :show-info="false" />
							</div>
						</div>
					</div>
					<p class="title">店铺形式:</p>
					<div class="type">
						<div :class="['type_box',videoType.typeIndex==index?'type_box_bg':'']"
							v-for="(item,index) in videoType.list" :key="index" @click="videoType.tabsType(index)">
							{{item.title}}
						</div>
					</div>
					<p class="title">头像:</p>
					<div class="avatar">
						<div class="avatar-box" v-if="videoData.headimgurl && videoData.headimgurl.progress>-1">
							<div v-if="videoData.headimgurl.progress!=101" class="avatar-upload">{{videoData.headimgurl.progress}}%</div>
							<img v-else :src="$baseFunc.getQiniusign(videoData.headimgurl.url)">
						</div>
						<a-button :disabled="videoData.headimgurl&&videoData.headimgurl.progress>-1&&videoData.headimgurl.progress!=101?true:false" class="btn" @click="selectFileData.addSingleImage(0,'headimgurl')">{{videoData.headimgurl&&videoData.headimgurl.url?"更换头像":"上传头像"}}</a-button>
						<a-popconfirm title="删除后不可恢复,确认删除该头像吗？" okText="删除" cancelText="取消" okType="danger" @confirm="deleteAvatar">
						    <template #icon><question-circle-outlined style="color: red" /></template>
							<a-button v-if="videoData.headimgurl && videoData.headimgurl.url" class="btn" style="margin-left: 23px;">删除头像</a-button>
						</a-popconfirm>
					</div>
					<p class="title">名称:</p>
					<div class="name">
						<a-input v-model:value="videoData.name" placeholder="请输入名称" />
					</div>
					<p class="title">{{videoData.quantity_type==2?'作者详细介绍:':'介绍:'}}</p>
					<div class="introduce">
						<a-input v-if="videoData.quantity_type==2" v-model:value="videoData.author_introduce"
							placeholder="请输入您的简介" />
						<a-textarea v-else class="textarea" v-model:value="videoData.author_introduce" showCount :autoSize="{ minRows: 4}" :maxlength="150" placeholder="请输入您的简介" />
					</div>
					<div v-if="videoData.quantity_type==2">
						<p class="title">课程介绍:</p>
						<div class="introduce">
							<a-textarea class="textarea" v-model:value="videoData.course_introduce" showCount :maxlength="150" :autoSize="{ minRows: 4}" placeholder="请输入您的简介" />
						</div>
					</div>
					<div v-if="videoData.quantity_type!=2 && videoData.content.lists[0]">
						<p class="title">视频标题:</p>
						<div class="video_title">
							<a-input v-model:value="videoData.content.lists[0].title" placeholder="请输入该视频的标题" />
						</div>
					</div>
					<div v-if="videoData.quantity_type!=2">
						<p class="title">标签:</p>
						<div class="tag">
							<a-input v-model:value="videoData.label" :maxlength="12" placeholder="请输入标签" />
						</div>
						<div style="display: flex;">
							<div style="margin-right:20px;">
								<p class="title">价格:</p>
								<div class="tag">
									<a-input style="width:70px;" v-model:value.number="videoData.price" />
									<span>元</span>
								</div>
							</div>
							<div style="margin-right:20px;">
								<p class="title">原价:</p>
								<div class="tag">
									<a-input style="width:70px;" v-model:value.number="videoData.original_price" />
									<span>元</span>
								</div>
							</div>
							<div style="margin-right:20px;" v-if="videoData.content.lists[0]">
								<p class="title">学习人数:</p>
								<div class="tag">
									<a-input style="width:50px;" v-model:value.number="videoData.content.lists[0].set_learn_nums" /><span>个已学</span>
								</div>
							</div>
							<div style="margin-right:20px;" v-if="videoData.content.lists[0]">
								<p class="title">试看时长:</p>
								<div class="tag">
									<a-input style="width:50px;"
										v-model:value.number="videoData.content.lists[0].free_time" /> <span>分钟</span>
								</div>
							</div>
							<div style="margin-right:20px;" v-if="videoData.content.lists[0]">
								<p class="title">点赞数:</p>
								<div class="tag">
									<a-input style="width:50px;" v-model:value.number="videoData.content.lists[0].set_like_nums" />
									<span>个</span>
								</div>
							</div>
						</div>
					</div>
					<div v-if="videoData.quantity_type==2">
						<div style="display: flex;">
							<div style="margin-right:20px;">
								<p class="title">价格:</p>
								<div class="tag">
									<a-input style="width:70px;" v-model:value.number="videoData.price" />
									<span>元</span>
								</div>
							</div>
							<div style="margin-right:20px;">
								<p class="title">原价:</p>
								<div class="tag">
									<a-input style="width:70px;" v-model:value.number="videoData.original_price" />
									<span>元</span>
								</div>
							</div>
							<div style="margin-right:20px;">
								<p class="title">标签:</p>
								<div class="tag">
									<a-input v-model:value="videoData.label" :maxlength="12" placeholder="请输入标签" />
								</div>
							</div>
						</div>
					</div>
					<div v-if="videoData.quantity_type==2">
						<p class="title">上传课程:<text class="title-r">拖拽可排序</text></p>
						<div @dragover="dragEvent.dragover($event)">
							<transition-group name="sort" tag="div">
								<div v-for="(item,index) in videoData.content.lists" :key="item.unique_key" @click="videoList.selectBox(index)"
								:draggable="true" 
								@dragstart="dragEvent.dragstart(item.unique_key)" 
								@dragenter="dragEvent.dragenter(item.unique_key,$event)"
								@dragend="dragEvent.dragend()" @dragover="dragEvent.dragover($event)">
									<div class="video_list" v-if="item.progress==101">
										<div class="video_list_box">
											<div class="video_list_img">
												<img :src="item.cover.url?$baseFunc.getQiniusign(item.cover.url):$baseFunc.getQiniusign(item.url,0)"/>
												<div v-if="item.cover && item.cover.id==-1" class="list_img_shade_j">{{item.cover.progress}}%</div>
											</div>
										</div>
										<div class="video_list_text" :style="videoList.inputIndex==index?'margin-right:57px;':'margin-right:86px;'">
											<!-- <input :ref="'input'+index" type="text" v-model="item.title" :disabled='item.disabled' @blur="videoList.loseFocus(index)"> -->
											<input :ref="'input'+index" type="text" v-model="item.title" @blur="videoList.loseFocus(index)">
											<img src="/img/knowledgePayment/xiugai.png" @click.stop="videoList.editInput(item,index)" v-if="videoList.inputIndex==index">
										</div>
										<div class="video_list_parameter">
											<div class="video_list_parameter_top">
												<span style="color: #285AF9;margin-left:0px;" @click="selectFileData.addSingleVideo(index,'video',false)">更换视频</span>
												<span @click="selectFileData.addSingleImage(index,'video_cover')">改封面</span>
												<span @click="preview.openModal(item)">浏览</span>
												<span @click="videoList.delVideo(index)">删除</span>
											</div>
											<div class="video_list_parameter_bottom">
												<span>学习人数</span>
												<input type="text" v-model="item.set_learn_nums">
												<span style="margin-right:6px;">{{item.charge?'免费':'付费'}}</span>
												<a-switch size="small" v-model:checked="item.charge" />
											</div>
										</div>
									</div>
									<div class="loadingConl" v-else>
										<a-progress strokeWidth="6" trailColor="white" :percent="item.progress" status="active" :show-info="false" />
									</div>
								</div>
							</transition-group>
						</div>
						<div class="loadingConl" v-if="videoList.upLoading">
							<a-progress strokeWidth="6" trailColor="white" :percent="videoList.progress" status="active" :show-info="false" />
						</div>
						<a-button class="add_video" @click="selectFileData.addSingleVideo(-1,'video')"><template #icon><PlusCircleOutlined /></template>添加视频</a-button>
						<!-- <div class="add_video" @click="selectFileData.addSingleVideo(-1,'video')">
							<img src="/img/knowledgePayment/icon-add.png">
							<span>添加视频</span>
						</div> -->
					</div>
				</div>
			</div>
			<div class="right_code" v-if="pageShow==0">
				<div class="top_title">
					<div :class="['title_box',topTitle.editIndex==index?'title_box_color':'']" v-for="(item,index) in topTitle.list" :key="index" @click="topTitle.tab(index,1)">
						<div class="title">{{item.title}}</div>
					</div>
				</div>
				<div class="code">
					<div class="code-img-xju2"><img v-if="linkCode.path" :src="linkCode.path"></div>
					<div class="tips">手机扫码浏览</div>
					<div class="up_video" @click="selectFileData.addSingleVideo(-1,'video',true)">上传视频</div>
					<div class="knowledge"></div>
					<div class="introduce">
						<span style="color:#D5D5D5;">&gt;</span>
						<span style="color:#B0B0B0;">&gt;</span>
						<span>1080P无损高清播放</span>
						<span style="margin:0 13px;">|</span>
						<span>一次转换终生有效</span>
						<span style="margin:0 13px;">|</span>
						<span>一键上传极速审核</span>
						<span style="color:#B0B0B0;">&lt;</span>
						<span style="color:#D5D5D5;">&lt;</span>
					</div>
				</div>
			</div>
			<div class="right_submit" v-if="pageShow==2">
				<img :src="`${linkCode.path}`" v-if="linkCode.path">
				<div v-else class="link_box">
					<span>{{linkCode.qrcode_url}}</span>
				</div>
				<div v-if="linkCode.path" class="download_code" @click="toDownloadQrcode">下载二维码</div>
				<div v-else class="download_code" @click="copyToClipboard()">复制链接</div>
				<div class="code_btn" style="margin:24px 0;" @click="toUpVideo()">继续修改</div>
				<div class="code_btn" @click="selectFileData.addSingleVideo(-1,'video',true)">再做一个</div>
				<div class="detailed">
					{{linkCode.path?'二维码':'链接'}}详细设置
					<span class="qrcode-set" @click="qrcodeSet.openSetModal">点击设置</span>
				</div>
				<div class="introduce" v-if="linkCode.path">
					<span style="color:#D5D5D5;">&gt;</span>
					<span style="color:#B0B0B0;">&gt;</span>
					<span>1080P无损高清播放</span>
					<span style="margin:0 13px;">|</span>
					<span>一次转换终生有效</span>
					<span style="margin:0 13px;">|</span>
					<span>一键上传极速审核</span>
					<span style="color:#B0B0B0;">&lt;</span>
					<span style="color:#D5D5D5;">&lt;</span>
				</div>
				<div class="link" v-else>
					可设置：链接查看权限、底部设置、下载设置
				</div>
			</div>
		</div>
		
		<!-- 媒体选择组件 -->
		<input ref="addSingleVideoRef" type="file" accept="video/*" @change="selectFileData.addSingleVideoChange($event)" v-show="false" />
		<input ref="addSingleImageRef" type="file" accept="image/*" @change="selectFileData.addSingleImageChange($event)" v-show="false" />
		
		<!-- 视频预览 -->
		<a-modal title="预览" :visible="preview.previewBool" @cancel="preview.previewBool = false" :footer="null" :destroyOnClose="true" :width="600">
			<player :pageType="1" :info="preview.info"></player>
		</a-modal>
		<!-- 二维码详细设置 -->
		<a-modal wrapClassName="qrcode-set-warp-xj18sh2" :destroyInClose="true" :footer="null" :centered="true" width="1000px" :visible="qrcodeSet.visible" title="设置" @cancel="qrcodeSet.cancel" @ok="qrcodeSet.ok">
			<div class="qrcode-set-box-h18s">
				<settings :styleConfig="qrcodeSet.styleConfig" :showConfig="qrcodeSet.showConfig"  @getsetData="qrcodeSet.callSetData"  :idKey="linkCode.id_key"></settings>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import knowledgeLeft from "@/components/knowledgeLeft.vue";
	import knowledgeVideoList from "@/components/knowledgeVideoList.vue";
	import player from "@/components/player.vue";
	import settings from "@/components/settings.vue";
	import { message } from 'ant-design-vue';
	import { v4 as uuidv4 } from 'uuid';
	import { PlusCircleOutlined,QuestionCircleOutlined } from '@ant-design/icons-vue';
	export default {
		components: {
			PlusCircleOutlined,QuestionCircleOutlined,
			knowledgeLeft,
			knowledgeVideoList,
			player,
			settings
		},
		data() {
			return {
				pageShow:0, //页面显示 0:初始化 1:单视频编辑页面 2:提交页面
				topTitle: { //单/多视频切换
					editIndex: 0, //编辑当前下标
					list: [ //循环列表
						{
							title: "单个视频",
							type: 1
						},
						{
							title: "多个视频",
							type: 2
						},
					],
					tab: (index, type) => { //切换tab
						if(this.topTitle.editIndex!=index){
							this.topTitle.editIndex = index
							this.videoData.quantity_type = this.topTitle.list[index].type;
							if(type==1){
								/*模板预览-切换预览效果*/
								this.tplData.switchShow(this.videoData.quantity_type);
							}
						}
					}
				},
				dragEvent:{
					unique_key:"",
					new_unique_key:"",
					dragstart:(value)=> {
						this.dragEvent.unique_key = value;
					},
					// 记录移动过程中信息
					dragenter:(value, e)=> {
						this.dragEvent.new_unique_key = value;
						e.preventDefault();
					},
					// 拖拽最终操作
					dragend:()=> {
						if (this.dragEvent.unique_key !== this.dragEvent.new_unique_key) {
							let oldIndex = this.videoData.content.lists.findIndex((item) => item.unique_key == this.dragEvent.unique_key);
							let newIndex = this.videoData.content.lists.findIndex((item) => item.unique_key == this.dragEvent.new_unique_key);
							let newItems = [...this.videoData.content.lists];
							let oldItem = JSON.stringify(this.videoData.content.lists[oldIndex]);
							newItems.splice(oldIndex, 1);
							newItems.splice(newIndex, 0, JSON.parse(oldItem));
							this.videoData.content.lists = [...newItems];
						}
					},
					// 拖动事件（主要是为了拖动时鼠标光标不变为禁止）
					dragover:(e)=> {
						e.preventDefault()
					},
				},
				videoType: { //转链接/二维码切换
					typeIndex: 1, //选中下标
					list: [ //循环列表
						{
							title: '链接形式',
							type: 2
						},
						{
							title: '二维码形式',
							type: 1
						},
					],
					tabsType: index => { //切换下标
						this.videoType.typeIndex = index
						if (index) {
							this.videoData.content.change_type = 1
						} else {
							this.videoData.content.change_type = 2
						}
					}
				},
				videoData: { //视频数据
					type: 'video', //video：视频 audio：音频
					content: {
						"title": "标题",
						"mode": 3,
						/*打开方式：1：小程序，2：无标识小程序，3：h5*/
						"change_type": 1,/*1：二维码 2：链接*/
						"design": 1,
						/*1：标准 2：视频集合 3：刷一刷*/
						"bjcolor": "#FFFFFF",
						/*背景色*/
						"url_info": {
							url_type: 1, //域名:1默认，2vip，3自有
							url: ''
						},
						"lists": []
					},
					headimgurl: {
						progress:-1,
						id:0,
						url:"",
						height:0,
						width:0,
						filesize:'',
						filesize_byte:0,
					}, //头像
					name: '', //名称
					author_introduce: '', //只有多视频才会有作者介绍
					course_introduce: '', //课程介绍
					label: '', //标签
					original_price: 0, //原价
					price: 0, //现价
					quantity_type: 1, //视频类型，1：单视频，2：多视频
					charge_type: 2, //1：普通 2：知识付费 3：其他
				},
				linkCode: { //添加后的链接或者二维码数据
				},
				videoList: { //多视频
					inputIndex: 0, //输入框下标
					upLoading: false, //上传中
					progress: 0, //进度条
					editInput: (item, index) => { //编辑输入框
						item.disabled = false
						item.iconBool = false
						this.$refs[`input${index}`][0].focus()
					},
					selectBox(index) { //选中列表
						this.inputIndex = index
					},
					loseFocus: index => { //input失去焦点
						this.videoData.content.lists[index].iconBool = true
						this.videoData.content.lists[index].disabled = true
					},
					delVideo: index => { //删除视频
						if(this.videoData.content.lists.length<2){
							message.warning("至少保留一个视频");
							return false;
						}else{
							this.videoData.content.lists.splice(index, 1)
						}
					},
				},
				preview: { //预览
					previewBool: false, //多视频预览
					info: null, //数据
					openModal: item => { //预览
						this.preview.info = item
						this.preview.previewBool = true
					}
				},
				qrcodeSet:{/*二维码设置*/
					visible:false,
					styleConfig: {
						iframe: {
							// width:'200px',//默认320px
							// height:'400px',//默认900px
							//...
						},
						settings: {
							// width:'300px',//默认500px
							//...
						}
					},
					//显示内容设置，可不填
					showConfig: {
						iframe: true, //显示左侧预览iframe
						settings: true, //显示右侧设置整体
						settings_qrcode: true, //设置头部二维码图片+标题
						settings_list: true, //二维码设置部分收起/展开
						settings_list_expire: true, //设置有效期
						settings_list_auth: true, //设置查看权限
						settings_list_open: true, //设置打开方式（链接类型此项不论设置什么值最终都为false）
						settings_list_bot: true, //底部设置
						settings_list_video: true //视频下载设置
					},
					openSetModal:()=>{
						this.qrcodeSet.visible=true;
					},
					callSetData(res){
						if(typeof res.qrcode_info!='undefined' &&  res.qrcode_info.mode){
							this.userDefultSet.mode = res.qrcode_info.mode
							this.userDefultSet.modeChange = true
						}else if(typeof res.set_config!='undefined' &&  res.set_config.mode){
							this.userDefultSet.mode = res.set_config.mode
							this.userDefultSet.modeChange = true
						}
					},
					cancel:()=>{
						this.qrcodeSet.visible=false;
					},
					ok:()=>{
						this.qrcodeSet.visible=false;
					},
				},
				selectFileData:{
					way:"",/*类型*/
					index:-1,/*-1：新增 >-1：修改*/
					initStatus:false,/*是否需要初始化*/
					addMoreVideoChange:(e)=>{//一次选择多个视频
						
					},
					addSingleVideo:(index,way,initStatus)=>{
						this.selectFileData.index=index;
						this.selectFileData.way=way;
						this.selectFileData.initStatus=initStatus;
						this.$refs.addSingleVideoRef.dispatchEvent(new MouseEvent('click'));
					},
					addSingleVideoChange:(e)=>{//选择单个视频
						//初始化数据
						if(this.selectFileData.initStatus){
							this.initVideoInit();
							this.pageShow=1;
						}
						let file = e.target.files[0];
						if(this.selectFileData.index>-1 && this.videoData.content.lists.length<1)this.selectFileData.index = -1;
						let unique_key = uuidv4();
						if(this.selectFileData.index<0){
							/*新增*/
							this.videoData.content.lists.push({
								"id": 0,
								"unique_key":unique_key,
								/*必传*/
								"sort": 0,
								/*必传-排序*/
								"title": file.name,
								"desc": "",
								"cover": {/*封面图*/
									"id": 0,
									"url": "",
								},
								"disabled": true, //输入框禁用
								"progress": 0, //进度条
								"charge": false, //是否收费
								"filesize": this.$baseFunc.converSize(file.size),
								"filesize_byte":file.size,
								"url": "",
								"width": 0,
								"height": 0,
								"duration": 0,
								"extra": {
									"title": "",
									"desc": "",
									"show_desc": 1,
									/*1：显示 0：隐藏*/
									"play_amount_set": 0,
									/*播放量预设*/
									"avatar": {
										"id": 0,
										"url": "",
									}
								},
								"original_price":0,
								/*原价*/
								"price": 0,
								/*现价*/
								"free_time": 0,
								/*试看时长/分钟*/
								"set_learn_nums": 0,
								/*PC后台设置学习人数*/
								"real_learn_nums": 0,
								/*真实学习人数，创建的时候默认0，编辑的时候传后端返回数值*/
								"set_like_nums": 0,
								/*PC后台设置点赞数*/
								"real_like_nums": 0,
								/*真实点赞数，创建的时候默认0，编辑的时候传后端返回数值*/
							});
							this.selectFileData.index = 0;
						}else{
							/*编辑*/
							unique_key = this.videoData.content.lists[this.selectFileData.index].unique_key;
							this.videoData.content.lists[this.selectFileData.index].progress=0;
						}
						this.$apiRequest.uploadMediaToQiniu(this, file, progress => {
							/*进度条*/
							let index = this.videoData.content.lists.findIndex((item) => item.unique_key == progress.extra_data_defult.unique_key);
							if(index>-1)this.videoData.content.lists[index].progress=progress.percent;
						}, complete => {
						}, {
							"upload_type": "video",/*video：只能上传视频 audio：只能上传音频 media：音视频都可以上传（默认）*/
							"index":this.selectFileData.index,
							"way":this.selectFileData.way,
							"unique_key":unique_key,
						}).then(res => {
							let index = this.videoData.content.lists.findIndex((item) => item.unique_key == res.extra_data_defult.unique_key);
							if(index>-1){
								this.videoData.content.lists[index].title=res.filename;
								this.videoData.content.lists[index].filesize=res.filesize;
								this.videoData.content.lists[index].width=res.width;
								this.videoData.content.lists[index].height=res.height;
								this.videoData.content.lists[index].id=res.id;
								this.videoData.content.lists[index].url=res.url;
								this.videoData.content.lists[index].progress=101;
							}
						}).catch(err => {
						});
						this.$refs.addSingleVideoRef.value = null
					},
					/*
					 *点击触发文件选择
					 * index 1：新增 >-1：修改
					 * way:"video_cover"：修改视频封面图
					 * */
					addSingleImage:(index,way)=>{
						this.selectFileData.index=index;
						this.selectFileData.way=way;
						this.$refs.addSingleImageRef.dispatchEvent(new MouseEvent('click'));
					},
					addSingleImageChange:(e)=>{//选择单个附件
						let files = e.target.files;
						let unique_key = "";
						if(this.selectFileData.way=="video_cover"){
							unique_key = this.videoData.content.lists[this.selectFileData.index].unique_key;
							this.videoData.content.lists[this.selectFileData.index].cover={
								id:-1,
								url:'',
								progress:0,
							};
						}
						this.$apiRequest.uploadMediaToQiniu(this, files[0], progress => {
							/*进度条*/
							switch(progress.extra_data_defult.way){
								case "video_cover"://视频选择封面图
									let index = this.videoData.content.lists.findIndex((item) => item.unique_key == progress.extra_data_defult.unique_key);
									if(index>-1)this.videoData.content.lists[index].cover.progress=progress.percent;
									break;
								case "headimgurl"://上传作者头像
									this.videoData.headimgurl.progress=progress.percent;
									break;
							}
						}, complete => {}, {
							"upload_type": 'img',
							"index":this.selectFileData.index,
							"way":this.selectFileData.way,
							"unique_key":unique_key,
						}).then(res => {
							let imgObj = {
										id:res.id,
										url:res.url,
										height:res.height,
										width:res.height,
										filesize:res.filesize,
										filesize_byte:res.filesize_byte,
										progress:101,
									};
							switch(res.extra_data_defult.way){
								case "video_cover":
									let index = this.videoData.content.lists.findIndex((item) => item.unique_key ==res.extra_data_defult.unique_key);
									this.videoData.content.lists[index].cover=imgObj
									break;
								case "headimgurl":
									this.videoData.headimgurl=imgObj
									break;
							}
						}).catch(err => {
						});
						this.$refs.addSingleImageRef.value = null
					},
				},
				tplData:{//模板数据
					oneData:null,
					moreData:null,
					useDataFunc:(res)=>{
						if(res){
							this.initVideoInit();
							if(res.data.quantity_type==1){
								this.topTitle.editIndex = 0
							}else{
								this.topTitle.editIndex = 1
							}
							if(res.data.content.change_type==2){
								/*转链接*/
								this.videoType.typeIndex=0;
							}else{
								/*转二维码*/
								this.videoType.typeIndex=1;
							}
							this.videoData = JSON.parse(JSON.stringify(res.data));
							this.videoData.type="video";
							this.linkCode=res.extra;
						}
					},
					switchShow:(type)=>{
						if(type==1){
							this.tplData.useDataFunc(JSON.parse(this.tplData.oneData))
						}else if(type==2){
							this.tplData.useDataFunc(JSON.parse(this.tplData.moreData))
						}
					}
				},
				userDefultSet:{
					data:null,
					defaultMode:1,//默认打开方式
					mode:1,
					modeChange:false,/*如果用户手动修改过数据，初始化时不设置此参数*/
					getUserDefultSet: (state) => {
						const that = this;
						return new Promise(function(resolve, reject) {
							if(that.userDefultSet.data && that.userDefultSet.data.vip_status==1){
								resolve(that.userDefultSet.data);
							}else{
								that.$apiRequest.post('/user/getUserDefultSet', {
								}).then(res => {
									that.userDefultSet.data = res.data.data;
									if(!that.userDefultSet.modeChange)that.userDefultSet.mode = res.data.data.mode;
									that.userDefultSet.defaultMode = res.data.data.mode;
									if(res.data.data.vip_status==1){
										resolve(res.data.data)
									}else{
										let tipMessageObj = "";
										switch(state){
											case 1://视频下载提示
												tipMessageObj={
													code: 11001,
													message: res.data.data.media_download_message,
												}
												break;
											case 2://打开方式提示
												tipMessageObj={
													code: 11001,
													message: res.data.data.mode_message,
												}
												break;
										}
										if(tipMessageObj)that.$baseFunc.checkState(tipMessageObj).then(res => {}).catch(err => {});
										reject(res.data.data)
									}
								}).catch(err => {
									reject(err)
								})
							}
							
						})
					}
				}
			};
		},
		mounted() {},
		created() {
			/*获取用户默认数据*/
			this.userDefultSet.getUserDefultSet(0).then(res => {}).catch(err => {})
			const that = this;
			/*判断是初始化数据还是用户修改*/
			if(id_key){
				this.getInit(id_key,function(res){
					if(res){
						that.userDefultSet.modeChange = true;
						that.userDefultSet.mode = res.data.mode;
						that.tplData.useDataFunc(res)
						that.pageShow = 1;
					}else{
						id_key="";
						that.tplData.switchShow(1)
					}
				});
			}else{
				if(!one_id_key||!two_id_key){
					this.initVideoInit();
					this.pageShow = 1;
				}
			}
			if(one_id_key){
				this.getInit(one_id_key,function(res){
					if(res){
						that.tplData.oneData=JSON.stringify(res);
						// if(!id_key)that.tplData.switchShow(1);
					}
				})
			}
			if(two_id_key){
				this.getInit(two_id_key,function(res){
					if(res){
						that.tplData.moreData=JSON.stringify(res)
						if(!id_key)that.tplData.switchShow(2);
					}
				})
			}
		},
		beforeDestroy() {},
		watch: {},
		methods: {
			/*初始化数据*/
			initVideoInit(quantity_type){
				if(!quantity_type){
					quantity_type=this.topTitle.list[this.topTitle.editIndex].type;
				};
				this.linkCode={};
				this.videoData=JSON.parse(JSON.stringify({ //视频数据
					type: 'video', //video：视频 audio：音频
					id_key:'',
					content: {
						"title": "标题",
						"mode": 3,/*打开方式：1：小程序，2：无标识小程序，3：h5*/
						"change_type": 1,/*1：二维码 2：链接*/
						"design": 1,/*1：标准 2：视频集合 3：刷一刷*/
						"bjcolor": "#FFFFFF",/*背景色*/
						"url_info": {
							url_type: 1, //域名:1默认，2vip，3自有
							url: ''
						},
						"lists": []
					},
					set_config: { //二维码设置
					},
					id_key: null, //修改二维码时必传
					parameter: '', //二维码美化参数
					headimgurl: {
						progress:-1,
						id:0,
						url:"",
						height:0,
						width:0,
						filesize:'',
						filesize_byte:0,
					}, //头像
					name: '', //名称
					author_introduce: '', //只有多视频才会有作者介绍
					course_introduce: '', //课程介绍
					label: '', //标签
					original_price: 0, //原价
					price: 0, //现价
					quantity_type: quantity_type, //视频类型，1：单视频，2：多视频
					charge_type: 2, //1：普通 2：知识付费 3：其他
				}))
			},
			getInit(id_key,fn) { //初始化获取数据
				this.$apiRequest.post('/qrcode/getQrcodeList', {
					id_key:id_key
				}).then(res => {
					if(fn)fn(res.data)
				}).catch(err => {
					if(fn)fn(false)
				})
			},
			
			toUpVideo(type, bool) { //继续编辑
				this.pageShow = 1
			},
			deleteAvatar(){/*删除头像*/
				this.videoData.headimgurl={
					progress:-1,
					id:0,
					url:"",
					height:0,
					width:0,
					filesize:'',
					filesize_byte:0,
				};
			},
			copyToClipboard() { //复制链接
				let targetDom = document.createElement('input'); //创建input节点
				targetDom.value = this.linkCode.qrcode_url; // 给input的value赋值
				document.body.appendChild(targetDom) // 向页面插入input节点
				targetDom.select() // 选中input
				try {
					document.execCommand('Copy') // 执行浏览器复制命令
					message.success('复制成功！');
				} catch (e) {
					
				}
				targetDom.remove();
			},
			toDownloadQrcode() {
				if(this.linkCode.download_url){
					window.location.href = this.linkCode.download_url
				}else{
					message.error('请先生成二维码或链接！');
				}
			},
			submit(ret){
				let content = JSON.parse(JSON.stringify(this.videoData.content));
				if(content.lists.length<1){
					message.warning('请上传视频文件');
					return false;
				}
				/*判断视频是否在上传中*/
				let videoUploadStatus = false;
				for(let i=0;i<content.lists.length;i++){
					if(content.lists[i].progress!=101){
						videoUploadStatus = true;
						break;
					}
				}
				if(videoUploadStatus){
					message.warning('视频正在上传中请稍后……');
					return false;
				}
				/*提交编辑*/
				if(ret.quantity_type==1){
					let arr = [];
					arr.push(this.videoData.content.lists[0]);
					content = {
						...this.videoData.content,
						lists: arr
					}
					this.$refs.knowledgeLeftRef.submitLoading=true;
				}else{
					this.$refs.knowledgeVideoListRef.submitLoading=true;
				}
				let edit_id_key='';
				if(typeof this.linkCode.id_key!='undefined' && this.linkCode.id_key)edit_id_key=this.linkCode.id_key;
				content.mode = this.userDefultSet.mode;
				this.$apiRequest.post('/qrcode/create', {
					...this.videoData,
					content: JSON.stringify(content),
					headimgurl:JSON.stringify(this.videoData.headimgurl),
					id_key:edit_id_key,
					set_config:this.videoData.set_config?JSON.stringify(this.videoData.set_config):'',
					parameter:this.videoData.parameter?JSON.stringify(this.videoData.parameter):'',
				}).then(res => {
					this.pageShow = 2;
					this.linkCode = res.data.data;
					message.success(res.data.message);
					if(ret.quantity_type==1){
						this.$refs.knowledgeLeftRef.submitLoading=false;
					}else{
						this.$refs.knowledgeVideoListRef.submitLoading=false;
					}
					if(res.data.data.free_user_tips){
						this.$baseFunc.checkState({
							code:11001,
							message:res.data.data.free_user_tips,
						}).then(res => {
						}).catch(err=>{});
					}
				}).catch(err => {
					if(ret.quantity_type==1){
						this.$refs.knowledgeLeftRef.submitLoading=false;
					}else{
						this.$refs.knowledgeVideoListRef.submitLoading=false;
					}
					if(err.data)this.$baseFunc.checkState(err.data);
				});
			},
		},
	};
</script>

<style scoped lang="less">
	// 公用部分
	.box {
		background-image: linear-gradient(#ecf3fc, #fff);
		// overflow: hidden;
		.content_box {
			width: 1200px;
			margin: auto;
			display: flex;
			justify-content: space-between;
			position: relative;
		}
	}

	p {
		margin: 0;
	}

	// 私有部分
	// 右侧编辑
	.right {
		width: 660px;
		height: auto;
		background: #FFFFFF;
		box-shadow: 0px 2px 28px -7px rgba(0, 0, 0, 0.06);
		border-radius: 10px;

		.top {
			display: flex;
			border-bottom: 1px solid #ECECEC;
			margin-bottom: 9px;

			span {
				cursor: pointer;
				display: inline-block;
				margin: 40px 24px 0px 36px;
				padding-bottom: 13px;
				font-size: 16px;
				color: #999999;
			}

			span:nth-child(2) {
				margin: 40px 0px 0px 0px;
			}

			.span_style {
				border-bottom: 2px solid #285AF9;
				margin-bottom: -1px !important;
				font-weight: bold;
				color: #333333;
			}
		}

		.content {
			padding-left: 36px;
			padding-bottom: 52px;
			.title {
				margin-top: 20px;
				margin-bottom: 8px;
				color: #333333;
				font-weight: bold;
				position: relative;
				    margin-right: 32px;
				.title-r{
					position: absolute;
					right: 0;
					bottom: 0;
					font-size: 12px;
					color: #999999;
					font-weight: normal;
				}
			}

			.video {
				.video_box {
					display: flex;
					align-items: center;

					.video_img {
						width: 132px;
						height: 74px;
						background-color: #e0ded9;
						border-radius: 10px;
						img,video {
							border-radius: 10px;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}

				.loadingCon {
					width: 617px;
					height: 65px;
					background-color: #edf3fc;
					display: flex;
					justify-content: center;
					border-radius: 8px;
					align-items: center;
					padding: 0 28px;
				}
			}

			.type {
				display: flex;

				.type_box {
					width: 190px;
					height: 34px;
					border-radius: 4px;
					// border: 1px solid #285AF9;
					background: url("/img/knowledgePayment/bk@2x.png") 0 0 no-repeat;
					background-size: 100% 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					cursor: pointer;
					color: #333333;
				}

				.type_box:nth-child(1) {
					margin-right: 23px;
				}

				.type_box_bg {
					background: url("/img/knowledgePayment/bk2@2x.png") 0 0 no-repeat;
					background-size: 100% 100%;
				}
			}

			.avatar {
				display: flex;
				align-items: center;
				.avatar-box{
					width: 60px;
					height: 60px;
					border-radius: 50px;
					position: relative;
					margin-right:18px;
					.avatar-upload,img {
						width:100%;
						height: 100%;
						border-radius: 50px;
					}
					.avatar-upload{
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: rgba(0,0,0,0.6);
						color: #ffffff;
						font-size: 14px;
					}
				}
			}

			.name {
				input {
					width: 403px;
					height: 34px;
					border-radius: 4px;
				}
			}

			.introduce {
				input {
					width: 403px;
					height: 34px;
					border-radius: 4px;
				}

				.textarea {
					width: 403px;
					height: auto;
					border-radius: 4px;
				}

				/deep/textarea {
					resize: none;
					height: 90px;
				}

				/deep/textarea:focus {
					outline: none;
				}

				/deep/.ant-input-textarea-show-count::after {
					bottom: 10px;
					right: 10px;
					position: relative;
					z-index: 10;
					margin-top: -20px;
					pointer-events: none;
				}
			}

			.video_title {
				input {
					width: 403px;
					height: 34px;
					padding: 0 12px;
				}
			}

			.tag {
				input {
					width: 104px;
					height: 34px;
					border-radius: 4px;
				}

				span {
					margin-left: 8px;
					color: #333333;
				}
			}

			.video_list {
				display: flex;
				align-items: center;
				height: 100%;
				margin-bottom: 20px;
				cursor: grabbing;
				.video_list_box {
					display: flex;
					align-items: center;

					.video_list_img {
						border-radius: 5px;
						width: 118px;
						height: 86px;
						background-color: #e0ded9;
						border-radius: 10px;
						position: relative;
						img,video {
							border-radius: 10px;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
						.list_img_shade_j{
							position:absolute;
							left: 0;
							top:0;
							width:100%;
							height:100%;
							background-color:rgba(0,0,0,0.85);
							display:flex;
							align-items:center;
							justify-content:center;
							color:#ffffff;
							font-size:14px;
							border-radius: 10px;
							z-index:2;
						}
					}
					
				}

				.video_list_text {
					display: flex;
					align-items: center;
					font-size: 14px;
					color: #333333;
					margin-left: 16px;

					input {
						border: none;
						width: 182px;
						overflow: hidden;
						text-overflow: ellipsis;
						background: transparent;

						&:focus {
							outline: none;
						}
					}

					img {
						margin-left: 9px;
						width: 20px;
						height: 20px;
						cursor: pointer;
					}
				}

				.video_list_parameter {
					display: flex;
					flex-direction: column;
					font-size: 12px;
					color: #555555;

					.video_list_parameter_top {
						span {
							margin-left: 18px;
							cursor: pointer;
							transition: color 0.2s;
						}
						span:hover{
							color:#285af9;
						}
					}

					.video_list_parameter_bottom {
						margin-top: 20px;
						display: flex;
						align-items: center;

						input {
							width: 36px;
							height: 24px;
							border-radius: 4px;
							border: 1px solid #D9D9D9;
							margin: 0 42px 0 4px;
							text-align: center;
							&:focus {
								outline: none;
							}
						}
					}
				}
			}

			.loadingConl {
				width: 588px;
				height: 65px;
				background-color: #edf3fc;
				display: flex;
				justify-content: center;
				border-radius: 8px;
				align-items: center;
				padding: 0 28px;
				margin: 20px 0;
			}

			.add_video {
				width: 588px;
				height: 52px;
				background-color: #f8f8f8;
				border:1px solid rgba(0,0,0,0);
				border-radius: 8px;
				text-align: center;
				font-size: 14px;
				color: #585858;
				cursor: pointer;
			}
			.add_video:hover{
				color: #285af9;
				background-color: #ffffff;
				border-color: #285af9;
			}
		}
	}

	//右侧初始预览
	.right_code {
		width: 660px;
		min-height: 792px;
		background: #FFFFFF;
		box-shadow: 0px 2px 28px -7px rgba(0, 0, 0, 0.06);
		border-radius: 10px;

		.top_title {
			display: flex;
			justify-content: center;
			margin-top: 68px;

			.title_box {
				padding: 9px 38px;
				display: flex;
				cursor: pointer;
				border: 1px solid #C7C7C7;
				color: #333333;
			}

			.title_box:nth-child(1) {
				border-radius: 6px 0px 0px 6px;
			}

			.title_box:nth-child(2) {
				border-radius: 0px 6px 6px 0px;
			}

			.title_box_color {
				border: 1px solid #285AF9;
				color: #285AF9;
			}
		}

		.code {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			margin-top: 68px;
			.code-img-xju2{
				width: 275px;
				height: 275px;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
			.tips {
				color: #999999;
				margin-top: 20px;
				margin-bottom: 64px;
			}
			.up_video {
				width: 180px;
				background: #285AF9;
				box-shadow: 0px 0px 11px 1px rgba(40, 137, 249, 0.4);
				border-radius: 35px;
				padding: 14px 0px;
				color: #fff;
				display: flex;
				justify-content: center;
				cursor: pointer;
			}

			.knowledge {
				font-size: 27px;
				font-weight: bold;
				color: #000000;
				width: 100%;
				height: 84px;
			}

			.introduce {
				display: flex;
				color: #999999;
				margin-bottom:77px;
			}
		}
	}

	//右侧提交
	.right_submit {
		width: 660px;
		min-height: 792px;
		background: #FFFFFF;
		box-shadow: 0px 2px 28px -7px rgba(0, 0, 0, 0.06);
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 275px;
			height: 275px;
			margin: 68px 0;
		}

		.download_code {
			width: 180px;
			background: #285AF9;
			box-shadow: 0px 0px 11px 1px rgba(40, 137, 249, 0.4);
			border-radius: 35px;
			padding: 0;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			height: 50px;
			font-size: 16px;
			transition: all 0.2s;
		}
		.download_code:hover{opacity: 0.9;}

		.code_btn {
			width: 180px;
			height: 50px;
			background: #FFFFFF;
			border: 1px solid #285AF9;
			border-radius: 35px;
			padding: 0;
			color: #285AF9;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			font-weight: 500;
			font-size: 16px;
			text-stroke: 0.1px #285AF9;
			-webkit-text-stroke: 0.1px #285AF9;
			transition: all 0.2s;
		}
		.code_btn:hover{background-color: #edf6ff;}
		.detailed {
			color: #333333;
			font-weight: bold;
			margin-top: 68px;
			margin-bottom: 8px;
			.qrcode-set{
				color:#285AF9;
				cursor: pointer;
				transition: all 0.2s;
			}
			.qrcode-set:hover{
				opacity: 0.85;
			}
		}

		.introduce {
			display: flex;
			color: #999999;
		}

		.link {
			font-size: 14px;
			color: #999999;
		}

		.link_box {
			margin: 125.5px auto;
			width: 540px;
			height: 160px;
			background: #FFFCEE;
			border-radius: 5px;
			border: 1px dashed #FAEDAF;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				font-size: 16px;
				font-weight: bold;
				color: #F58A00;
			}
		}
	}
</style>
