<template>
	<div class="right_ios"  :class="pageShow==1?'left-edit-box-2s2':''">
		<div class="phone_box_warp">
			<img class="phone_box_img" src="/img/top_black2@2x.png">
			<div class="phone_box_container">
				<div class="content">
					<div class="top_box">
						<div class="top_box_bg">
							<div class="avatar_box" v-if="videoData.headimgurl && videoData.headimgurl.url"><img class="avatar" :src="$baseFunc.getQiniusign(videoData.headimgurl.url)"></div>
							<div class="name">
								<span>{{videoData.name}}</span>
							</div>
							<div class="introduce">{{videoData.author_introduce}}</div>
							<div class="statistics">
								<div class="statistics_left">
									<span class="statistics_left_top">{{learnedNum}}</span>
									<span class="statistics_left_bottom">已学</span>
								</div>
								<div class="statistics_middle">
									|
								</div>
								<div class="statistics_right">
									<span class="statistics_left_top">{{fabulousNum}}</span>
									<span class="statistics_left_bottom">点赞</span>
								</div>
							</div>
						</div>
					</div>
					<div class="video_content">
						<div class="video_title">
							<span>全部课程</span>
						</div>
						<div class="video_introduce">
							<p>课程介绍:</p>
							<div>{{this.videoData.course_introduce}}</div>
						</div>
						<div class="video_list">
							<div class="video_list_box" v-for="(item,index) in videoData.content.lists"
								:key="index">
								<div class="video_list_box_size">
									<img :src='item.cover&&item.cover.url?$baseFunc.getQiniusign(item.cover.url):$baseFunc.getQiniusign(item.url,0)'/>
								</div>
								<div class="video_list_right">
									<div class="video_list_right_title">{{item.title}}</div>
									<div class="video_list_right_statistics">
										<a-tag color="#E6F2FC" style="border-radius: 4px;" ><span
												style="color:#0F58FB;font-size: 12px;">{{$baseFunc.playbackConversion(item.set_learn_nums,0)}}人已学</span>
										</a-tag>
										<a-tag color="#FFE5DA" style="border-radius: 4px;"  v-if="item.charge"><span
												style="color:#FF4839;font-size: 12px;">免费</span></a-tag>
										<div class="video_list_right_zan">
											<img src="/img/knowledgePayment/icon-dianzan.png">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="video_buy">
						<div class="shopping_tag">
							<span>{{videoData.label}}</span>
						</div>
						<div class="price">
							<span>￥</span>
							<span>{{videoData.price}}</span>
						</div>
						<div class="original_price">
							<s>￥{{videoData.original_price}}</s>
						</div>
						<div class="buy">
							<span>购买课程</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="submit" v-if="pageShow==1">
			<a-button class="submit_btn" size="large" type="primary" :loading="submitLoading" @click="submit">完成编辑</a-button>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			videoData: Object,
			pageShow: Number
		},
		data() {
			return {
				playVideoBool: false, //是否播放视频
				submitLoading:false,
			};
		},
		mounted() {},
		computed: {
			learnedNum() {
				let num = 0
				for (let i = 0; i < this.videoData.content.lists.length; i++) {
					num += parseInt(this.videoData.content.lists[i].set_learn_nums) + parseInt(this.videoData.content
						.lists[i].real_learn_nums)
				}
				return num
			},
			fabulousNum() {
				let num = 0
				for (let i = 0; i < this.videoData.content.lists.length; i++) {
					num += parseInt(this.videoData.content.lists[i].set_like_nums) + parseInt(this.videoData.content.lists[
						i].real_like_nums)
				}
				return num
			}
		},
		beforeDestroy() {},
		watch: {},
		methods: {
			submit() { //提交
				this.$emit('submitData', {
					quantity_type: 2
				})
			},
			async playVideo() { //播放视频
				this.playVideoBool = true
				if (this.$refs.video) {
					try {
						await this.$refs.video.play();
					} catch (e) {}
				}
			}
		},
	};
</script>

<style scoped lang="less">
	/deep/.btn {
		width: 80px;
		line-height: 34px;
		border-radius: 4px;
		border: 1px solid #D9D9D9;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	// 私有部分
	.right_ios {
		width: 517px;
		background: #FFFFFF;
		box-shadow: 0px 2px 28px -7px rgba(0, 0, 0, 0.06);
		border-radius: 10px;
		min-height: 792px;
		height: 792px;
		.phone_box_warp {
			height: calc(100% - 64px);
			width: 360px;
			margin: 32px auto;
			top: 0;
			border-radius: 40px;
			overflow: hidden;
			position: relative;
			background: white;
			box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.04);
			padding-bottom: 16px;
			.phone_box_img {
				position: absolute;
				top: 0;
				left: 1%;
				width: 98%;
				z-index: 5;
			}
			.phone_box_container {
				height: 100%;
				width: 340px;
				border-radius: 33px;
				margin: 7px 10px 0 10px;
				overflow: hidden;
				border: 3px solid #F5F5F5;
			}
		}
		.content {
			position: relative;
			height: 100%;
			overflow: hidden;
			.top_box_bg {
				padding: 99px 20px 20px;
				background: url("/img/knowledgePayment/bg-top.png") 0 0px no-repeat;
				background-size: 100% 100%;
			}

			.avatar_box {
				width: 100%;
				display: flex;
				justify-content: center;
				.avatar {
					width: 76px;
					height: 76px;
					border-radius: 50%;
					object-fit: cover;
				}
			}

			.name {
				display: flex;
				justify-content: center;
				font-size: 16px;
				color: #FFFFFF;
				align-items: center;
				font-weight: 550;
				width: 100%;
				margin:  6px auto 10px;
			}

			.introduce {
				display: flex;
				justify-content: center;
				width: 100%;
				margin: auto;
				font-size: 13px;
				text-align: left;
				color: #FFFFFF;
				line-height: 19px;
			}

			.statistics {
				width: 100%;
				height: 61px;
				background: rgba(255, 255, 255, 0.86);
				box-shadow: 0px 1px 2px 0px rgba(10, 33, 128, 0.04);
				border-radius: 6px;
				border: 1px solid #FFFFFF;
				margin: auto;
				margin-top: 19px;
				display: flex;
				justify-content: space-evenly;
				align-items: center;

				.statistics_left {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.statistics_middle {
					color: #CCCCCC;
				}

				.statistics_right {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.statistics_left_top {
					font-size: 15px;
					font-weight: 550;
					color: #434343;
				}

				.statistics_left_bottom {
					font-size: 12px;
					color: #474747;
				}
			}

			.video_content {
				width: 100%;
				padding: 0 16px;
				overflow: hidden;
				.video_title {
					border-bottom: 1px solid rgba(239,239,239,0.5);
					height: 43px;
					padding-top: 12px;
					span {
						padding-bottom: 9px;
						font-weight: bold;
						color: #333333;
						border-bottom: 2px solid #4273F1;
					}
				}
				.video_introduce {
					margin-top: 15px;
					padding-bottom: 14px;
					border-bottom: 1px solid rgba(239,239,239,0.5);
					p {
						margin: 0;
						color: #353739;
						font-size: 13px;
						font-weight: 550;
					}

					div {
						font-size: 13px;
						color: #71777B;
						line-height: 20px;
						white-space: pre-line;
					}
				}

				.video_list {
					margin-top: 3px;

					.video_list_box {
						padding: 20px 0;
						border-bottom: 1px solid #F5F5F5;
						display: flex;
						align-items: center;

						.video_list_box_size {
							background-color: #e0ded9;
							border-radius: 5px;
							display: flex;
							align-items: center;
							min-width: 118px;
							width: 118px;
							height: 86px;
							overflow: hidden;
							video,img {
								width: 100%;
								height: 100%;
							}
							img{object-fit: cover;}
						}

						.video_list_right {
							margin-left: 11px;
							min-height: 86px;
							display: flex;
							flex-direction: column;
							justify-content: space-around;
							flex:1;
							.video_list_right_title {
								font-size: 14px;
								font-weight: 550;
								color: #333333;
							}

							.video_list_right_statistics {
								width:100%;
								position:relative;
								a-tag {
									border-radius: 4px;
								}
								.video_list_right_zan{
									position: absolute;
									bottom:2px;
									right:0;
									img{
										height:18px;
									}
								}
							}
						}
					}
				}
			}

			.video_buy {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 76px;
				border-radius: 5px 5px 0 0;
				padding: 0 16px;
				border-top: 1px solid #F7F7F7;
				display: flex;
				align-items: center;
				background: #ffffff;
				z-index: 1;
				.shopping_tag {
					padding: 0px 6px;
					height: 24px;
					background: linear-gradient(180deg, #FF853E 0%, #FF3838 100%);
					border-radius: 4px;
					display: flex;
					justify-content: center;
					align-items: center;

					span {
						font-size: 13px;
						color: #FFFFFF;
						line-height: 1;
					}
				}

				.price {
					margin-left: 8px;

					span:nth-child(1) {
						font-size: 12px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 550;
						color: #FF4E3A;
					}

					span:nth-child(2) {
						font-size: 16px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 550;
						color: #FF2222;
					}
				}

				.original_price {
					margin-left: 11px;

					s {
						font-size: 14px;
						color: #B6B6B6;
					}
				}

				.buy {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					right: 14px;
					width: 108px;
					height: 35px;
					background: linear-gradient(180deg, #FF853E 0%, #FF3838 100%);
					border-radius: 18px;

					span {
						font-size: 14px;
						font-weight: bold;
						color: #FFFFFF;
					}
				}
			}
		}
		.submit {
			padding: 40px 0 0px;
			display: flex;
			align-items: center;
			justify-content: center;

			.submit_btn {
				min-width: 156px;
			}
		}
	}
	
	.left-edit-box-2s2.right_ios{
		height: auto;
		.phone_box_warp{
			height: calc(100% - 64px - 34px - 40px - 66px);
		}
	}
</style>
