import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import apiAxios from '../../config/request.js'
import baseFunc from '../../config/base_func.js';

const app = createApp(App);
app.config.globalProperties.$apiRequest = apiAxios; 
app.config.globalProperties.$baseFunc = baseFunc; 
app.use(Antd).mount('#app');
